import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const virtualMachineTabPropertyFieldMap = () => ({
  virtual_machine: {
    title: __mtc('virtual_machine'),
    fields: [
      {
        key: generateId(),
        paramName: 'name',
        name: __mtc('name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'memorySize',
        name: __mtc('memory_size'),
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'UUID',
        name: __mtc('uuId'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'powerState',
        name: __mtc('power_state'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'hyperVType',
        name: __mtc('hyper_v_type'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'hostUUID',
        name: __mtc('host_uuid'),
        inputType: 'text',
      },
    ],
  },
})
