import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const NutanixVmPropertyFieldMap = () => ({
  nutanix_vm_property_group: {
    title: __mtc('nutanix_vm_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'vmName',
        name: __mtc('vm_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'UUID',
        name: __mtc('uuid'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'clusterUUID',
        name: __mtc('cluster_uuid'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'hostUUID',
        name: __mtc('host_uuid'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'hyperVType',
        name: __mtc('hyper_v_type'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'powerState',
        name: __mtc('power_state'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'hostName',
        name: __mtc('host_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'ipAddress',
        name: __mtc('ip_address'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'vmType',
        name: __mtc('vm_type'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'controllerVM',
        name: __mtc('controller_vm'),
        inputType: 'boolean',
      },
      {
        key: generateId(),
        paramName: 'acropolisVM',
        name: __mtc('acropolis_vm'),
        inputType: 'boolean',
      },
      {
        key: generateId(),
        paramName: 'memorySize',
        name: __mtc('memory_size'),
        inputType: 'bytes',
      },
    ],
  },
})
