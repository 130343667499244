// import { getRootPluaralTranslator } from '@utils/get-module-translator'
// const __rootTc = getRootPluaralTranslator()

export const osPlatformOptions = () => [
  { text: 'Windows', key: 'windows' },
  { text: 'MAC', key: 'mac' },
  // { text: 'Unix', key: 'unix' },
  { text: 'Linux Ubuntu', key: 'unix_ubuntu' },
  { text: 'Linux Mint', key: 'unix_mint' },
  { text: 'Linux Centos', key: 'unix_centos' },
  { text: 'Linux Redhat', key: 'unix_red_hat' },
  { text: 'Linux Opensuse', key: 'unix_open_suse' },
  { text: 'Linux Suse', key: 'unix_suse' },
  { text: 'Linux Debian', key: 'unix_debian' },
  { text: 'Linux Oracle', key: 'unix_oracle_linux' },
  { text: 'Linux Rocky', key: 'unix_rocky_linux' },
  { text: 'Linux Alma', key: 'unix_alma_linux' },
  // { text: 'Unix Fedora', key: 'unix_fedora' },
  // { text: 'Unix Solaris', key: 'unix_solaris' },
]
export const architectureOptions = () => [
  { text: 'All', key: 'all' },
  { text: '64 BIT', key: 'bit_64' },
  { text: '32 BIT', key: 'bit_32' },
  { text: 'ARM64', key: 'arm64' },
  { text: 'None', key: 'none' },
]

export const systemHealthSettingOptions = () => [
  { text: 'Healthy', key: 'healthy', color: '#3CB371' },
  { text: 'Vulnerable', key: 'vulnerable', color: '#f58518' },
  { text: 'Highly Vulnerable', key: 'highly_vulnerable', color: '#ff3636' },
]
