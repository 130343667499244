import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const hostTabPropertyFieldMap = () => ({
  host_tab: {
    title: __mtc('host_tab'),
    fields: [
      {
        key: generateId(),
        paramName: 'name',
        name: __mtc('name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'state',
        name: __mtc('state'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'numberOfVms',
        name: __mtc('number_of_vms'),
        inputType: 'number',
      },
      {
        key: generateId(),
        paramName: 'hostType',
        name: __mtc('host_type'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'cvmIP',
        name: __mtc('cvm_ip'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'cpuSpeed',
        name: __mtc('cpu_speed'),
        inputType: 'hertz',
      },
      {
        key: generateId(),
        paramName: 'memorySize',
        name: __mtc('memory_size'),
        inputType: 'bytes',
      },
    ],
  },
})
