import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const storageContainerTabPropertyFieldMap = () => ({
  storage_container: {
    title: __mtc('storage_container'),
    fields: [
      {
        key: generateId(),
        paramName: 'name',
        name: __mtc('name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'containerId',
        name: __mtc('container_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'uuid',
        name: __mtc('uuid'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'size',
        name: __mtc('size'),
        inputType: 'number',
      },
    ],
  },
})
