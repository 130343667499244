import modules from '@modules'
import store from '@state/store'
import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/`,
    component: ContainerView,
    meta: {
      moduleName,
      layout: 'LoginLayout',
      public: true,
      beforeResolve(routeTo, routeFrom, next) {
        // if user logged in and ask for approval through email
        if (routeTo.name.indexOf('approvallink') >= 0) {
          return next()
        }
        if (routeTo.name.indexOf('user-consent') >= 0) {
          return next()
        }
        // if user logged in and ask for reopen close request through email
        if (routeTo.name.indexOf('requestlink') >= 0) {
          return next()
        }
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // check query params with login page to allow login route with already logged in session
          if (
            routeTo.name === 'auth.login' &&
            Object.keys(routeTo.query).length
          ) {
            next()
          } else {
            // Redirect to the home page instead { name: 'dashboard.index' }
            next(modules.getModuleRoute('dashboard'))
          }
        } else {
          // Continue to the login page
          next()
        }
      },
    },
    children: [
      {
        path: 'login',
        name: `${routeNamePrefix}.login`,
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "auth" */ './views/login')),
        meta: {
          public: true,
        },
      },
      {
        path: 'login/two-factor-authentication',
        name: `${routeNamePrefix}.2fa-login`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "auth" */ './views/two-factor-auth-login'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'login/multi-factor-authentication-enroll',
        name: `${routeNamePrefix}.mfa-enroll`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "auth" */ './views/multi-factor-auth-enroll'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'login/multi-factor-authentication',
        name: `${routeNamePrefix}.mfa-login`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "auth" */ './views/multi-factor-auth-login'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'login/multi-factor-authentication-recovery',
        name: `${routeNamePrefix}.mfa-recovery`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "auth" */ './views/multi-factor-auth-recovery'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'login/multi-factor-authentication-reset',
        name: `${routeNamePrefix}.mfa-reset`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "auth" */ './views/multi-factor-auth-reset'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'signup',
        name: `${routeNamePrefix}.signup`,
        component: () =>
          lazyLoadView(import(/* webpackChunkName: "auth" */ './views/signup')),
        meta: {
          public: true,
        },
      },
      {
        path: 'register-tenant',
        name: `${routeNamePrefix}.register-tenant`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "auth" */ './views/register-tenant')
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'approvallink',
        name: `${routeNamePrefix}.approvallink`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "auth" */ './views/approval-page')
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'requestlink',
        name: `${routeNamePrefix}.requestlink`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "auth" */ './views/request-page')
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'forgot-password',
        name: `${routeNamePrefix}.forgot-password`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "auth" */ './views/forgot-password')
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'reset-password',
        name: `${routeNamePrefix}.reset-password`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "auth" */ './views/reset-password')
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'change-password',
        name: `${routeNamePrefix}.change-password-for-requester`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "auth" */ './views/change-password-for-requester'
            )
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'sso-login',
        name: `${routeNamePrefix}.sso-login`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "auth" */ './views/sso-login-page')
          ),
        meta: {
          public: true,
        },
      },
      {
        path: 'user-consent',
        name: `${routeNamePrefix}.user-consent`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "auth" */ '@modules/auth/views/user-consent'
            )
          ),
        meta: {
          public: false,
        },
      },
    ],
  },
]
