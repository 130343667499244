import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const NutanixClusterPropertyFieldMap = () => ({
  nutanix_cluster_property_group: {
    title: __mtc('nutanix_cluster_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'clusterName',
        name: __mtc('cluster_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'clusterId',
        name: __mtc('cluster_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'UUID',
        name: __mtc('uuid'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'externalIP',
        name: __mtc('external_ip'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'FQDNName',
        name: __mtc('fqdn_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'operationMode',
        name: __mtc('operation_mode'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'encrypted',
        name: __mtc('encryption'),
        inputType: 'boolean',
      },
      {
        key: generateId(),
        paramName: 'storageType',
        name: __mtc('storage_type'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'functions',
        name: __mtc('functions'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'prismCentralRegistration',
        name: __mtc('prismCentral_registration'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'nodeCount',
        name: __mtc('node_count'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'version',
        name: __mtc('version'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'fullVersion',
        name: __mtc('full_version'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'externalSubnet',
        name: __mtc('external_subnet'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'internalSubnet',
        name: __mtc('internal_subnet'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'nCCVersion',
        name: __mtc('ncc_version'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'architecture',
        name: __mtc('architecture'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'storageCapacity',
        name: __mtc('storage_capacity'),
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'freeStorage',
        name: __mtc('free_storage'),
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'usedStorage',
        name: __mtc('used_storage'),
        inputType: 'bytes',
      },
    ],
  },
})
