import { getModuleTranslator } from '@utils/get-module-translator'
import { generateId } from '@utils/id'
import moduleConfig from '../../config'

const __mtc = getModuleTranslator(moduleConfig.translationKey)

export const NutanixStoragePoolPropertyFieldMap = () => ({
  nutanix_storage_pool_property_group: {
    title: __mtc('nutanix_storage_pool_property_group'),
    fields: [
      {
        key: generateId(),
        paramName: 'poolName',
        name: __mtc('pool_name'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'poolId',
        name: __mtc('pool_id'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'uuid',
        name: __mtc('uuid'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'clusterUUID',
        name: __mtc('cluster_uuid'),
        inputType: 'text',
      },
      {
        key: generateId(),
        paramName: 'size',
        name: __mtc('size'),
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'freeSpace',
        name: __mtc('free_space'),
        inputType: 'bytes',
      },
      {
        key: generateId(),
        paramName: 'usedSpace',
        name: __mtc('used_space'),
        inputType: 'bytes',
      },
    ],
  },
})
