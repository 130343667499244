import api from '@api'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'
import {
  transformDiscoveryAgents,
  transformAgentSetting,
  transformAgentSettingForServer,
  transformMobileAgentSetting,
  transformMobileAgentSettingForServer,
  transformDiscoveryPollerConfig,
  transformDiscoveryPollerConfigSetting,
  transformDiscoveryPollerConfigSettingForServer,
  transformAgentLogRequest,
  tranformCrendetialProfile,
  transformCreateCredentialProfileForServer,
} from '@data/discovery-agents'
import {
  // getModuleTranslator,
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import { successToast } from '@motadata/ui'
const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const deletedQuery = (value) =>
  buildRelationalQualificationStructure(
    'removed',
    'equal',
    value,
    'boolean',
    'db'
  )

export function getDiscoveryAgentsApi(searchCriteria, filters, limit, offset) {
  const qualDetails = [...((searchCriteria || {}).quals || [])]
  if ('archived' in filters) {
    qualDetails.push(deletedQuery(true))
  }
  return api
    .post(
      `/agent/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformDiscoveryAgents) || [],
        total: data.totalCount,
      }
    })
}

export function getMobileAgentsApi(searchCriteria, limit, offset) {
  const qualDetails = [...((searchCriteria || {}).quals || [])]
  return api
    .post(
      `/agent/mobile/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformDiscoveryAgents) || [],
        total: data.totalCount,
      }
    })
}

export function deleteDiscoveryAgentsApi(ids, isPermanentDelete) {
  return api
    .delete(`/agent/bulk/delete`, {
      data: { objectIds: ids },
      params: {
        isPermanentDelete: isPermanentDelete,
      },
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        if (isPermanentDelete === true) {
          successToast(
            `${data.successIds.length} ${__rootT(
              data.successIds.length > 1
                ? 'deleted_successfully_multiple'
                : 'deleted_successfully',
              {
                resource: __rootTc('discovery_agent', data.successIds.length),
              }
            )}`
          )
        } else if (isPermanentDelete === false) {
          successToast(
            `${data.successIds.length} ${__rootT(
              data.successIds.length > 1
                ? 'archived_successfully_multiple'
                : 'archived_successfully',
              {
                resource: __rootTc('discovery_agent', data.successIds.length),
              }
            )}`
          )
        }
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function restoreDiscoveryAgentsApi(ids) {
  return api
    .patch(`/agent/bulk/restore`, {
      objectIds: ids,
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          `${data.successIds.length} ${__rootT(
            data.successIds.length > 1
              ? 'updated_successfully_multiple'
              : 'updated_successfully',
            {
              resource: __rootTc('discovery_agent', data.successIds.length),
            }
          )}`
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function getAgentByAssetApi(id) {
  return api
    .get(`/${id}/agent`, {
      notify: false,
    })
    .then((data) => {
      return transformDiscoveryAgents(data)
    })
}
export function getAgentSettingApi(id) {
  return api
    .get(`/agentpreference/setting`, {
      notify: false,
    })
    .then((data) => {
      return transformAgentSetting(data)
    })
}

export function deleteAgentApi() {
  return api.delete(`/agentbuildautomation/agentbuild/remove`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('agent'),
    }),
  })
}

export function updateAgentSetting(data) {
  return api
    .patch(`/agentpreference/setting`, transformAgentSettingForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('agent_setting'),
      }),
    })
    .then(transformAgentSetting)
}

export function updateAgentServerCommnucationPrerernce(data) {
  return api.patch(
    `/agentpreference/setting`,
    {
      optForMoreSecureCommunication: data,
    },
    {
      message: __rootT('updated_successfully', {
        resource: __rootTc('agent_setting'),
      }),
    }
  )
}

export function getMobileAgentSettingsApi(id) {
  return api
    .get(`/mobilediscoveryconfig`, {
      notify: false,
    })
    .then((data) => {
      return transformMobileAgentSetting(data)
    })
}

export function updateMobileAgentSettings(data) {
  return api
    .patch(
      `/mobilediscoveryconfig`,
      transformMobileAgentSettingForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('mobile_agent_setting'),
        }),
      }
    )
    .then(transformMobileAgentSetting)
}

export function getMobileAgentSettingsQrCodeApi() {
  return api.get(`/mobilediscoveryconfig/qrcode`)
}

export function getCredentialProfileApi(
  filters,
  limit,
  offset,
  additionalParams = {}
) {
  let qualDetails = []
  if ('name' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        filters.name,
        'string',
        'db'
      )
    )
  }

  if (filters.filterType && filters.filterType === 'archived') {
    qualDetails.push(deletedQuery(true))
  }

  if (filters.filterType && filters.filterType !== 'archived') {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'disabled',
        'equal',
        filters.filterType !== 'enabled',
        'boolean',
        'db'
      )
    )
  }

  if ('ids' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }

  return api
    .post(
      `/credentialprofile/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...additionalParams,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(tranformCrendetialProfile) || [],
        total: data.totalCount,
      }
    })
}

export function createCredentialProfileApi(data) {
  return api.post(
    `/credentialprofile`,
    transformCreateCredentialProfileForServer(data),
    {
      message: __rootT('created_successfully', {
        resource: `${__rootTc('agent_credential_profile')}`,
      }),
    }
  )
}

export function toggleEnableCredentialProfileApi(id, data) {
  return api
    .patch(`credentialprofile/${id}`, data, {
      message: __rootT('updated_successfully', {
        resource: `${__rootTc('agent_credential_profile')}`,
      }),
    })
    .then((data) => tranformCrendetialProfile(data))
}

export function updateCredentialProfileApi(data) {
  return api
    .patch(
      `/credentialprofile/${data.id}`,
      transformCreateCredentialProfileForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: `${__rootTc('agent_credential_profile')}`,
        }),
      }
    )
    .then((data) => tranformCrendetialProfile(data))
}

export function getDiscoverPollerConfigApi(
  filters,
  searchCriteria,
  limit,
  offset
) {
  const qualDetails = [...((searchCriteria || {}).quals || [])]
  if (filters.name) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        filters.name,
        'string',
        'db'
      )
    )
  }
  if (filters.selectedItemNames && filters.selectedItemNames.length) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'name',
        'in',
        filters.selectedItemNames,
        'string',
        'db'
      )
    )
  }
  if (filters.ids) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  return api
    .post(
      `/poller/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items:
          (data.objectList || []).map(transformDiscoveryPollerConfig) || [],
        total: data.totalCount,
      }
    })
}

export function getDiscoveryPollerConfigSettingsApi() {
  return api
    .get(`/poller/config`, {
      notify: false,
    })
    .then((data) => {
      return transformDiscoveryPollerConfigSetting(data)
    })
}

export function updateDiscoveryPollerConfigSettingsApi(data) {
  return api
    .patch(
      `/poller/config`,
      transformDiscoveryPollerConfigSettingForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('discovery_poller'),
        }),
      }
    )
    .then(transformDiscoveryPollerConfigSetting)
}

export function deleteDiscoveryPollerConfigApi(ids) {
  return api.delete(`/poller/bulk/delete`, {
    data: { objectIds: ids },
    message: __rootT('deleted_successfully', {
      resource: __rootTc('discovery_poller'),
    }),
  })
}

export function deleteCredentialProfileApi(ids, isPermanentDelete) {
  return api
    .delete(`/credentialprofile/bulk/delete`, {
      data: { objectIds: ids },
      params: {
        isPermanentDelete: isPermanentDelete,
      },
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          `${data.successIds.length} ${__rootT(
            data.successIds.length > 1
              ? 'archived_successfully_multiple'
              : 'archived_successfully',
            {
              resource: __rootTc(
                'agent_credential_profile',
                data.successIds.length
              ),
            }
          )}`
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function restoreCredentialProfileApi(ids) {
  return api
    .patch(`/credentialprofile/bulk/restore`, {
      objectIds: ids,
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          `${data.successIds.length} ${__rootT(
            data.successIds.length > 1
              ? 'updated_successfully_multiple'
              : 'updated_successfully',
            {
              resource: __rootTc(
                'agent_credential_profile',
                data.successIds.length
              ),
            }
          )}`
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function requestLogApi(id, type) {
  if (type === 'agent') {
    return api.get(`/agent/log-download-request/${id}`, {
      message: __rootT('sent_successfully', {
        resource: __rootTc('request_agent_log'),
      }),
    })
  } else if (type === 'rdp_agent') {
    return api.patch(
      `/rdpagent/${id}`,
      {
        sendLogDownloadRequest: true,
      },
      {
        message: __rootT('sent_successfully', {
          resource: __rootTc('request_rdp_log'),
        }),
      }
    )
  } else {
    return undefined
  }
}
export function getAgentsLogApi(type, id, limit, offset) {
  return api
    .post(
      `/${type}/${id}/loghistory/search/byqual`,
      {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformAgentLogRequest) || [],
        total: data.totalCount,
      }
    })
}
